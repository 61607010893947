<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ id ? "Update" : "Add" }} Testimonial</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\
                  Testimonial
                </div>
              </div>
            </div>
          </div>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="10">
                <v-text-field
                  outlined
                  dense
                  label="Title"
                  v-model="testimonial.title"
                ></v-text-field>
                <span class="text-danger" v-if="$v.testimonial.title.$error"
                  >Title is required</span
                >
              </v-col>
              <v-col class="12" md="2">
                <v-text-field
                  outlined
                  dense
                  label="Position"
                  type="number"
                  v-model="testimonial.position"
                ></v-text-field>

                <span class="text-danger" v-if="$v.testimonial.position.$error"
                  >This Position Field is required</span
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-file-input
                  accept="image/*"
                  outlined
                  dense
                  :rules="rules"
                  @change="changeImage"
                  v-model="testimonial.imageFile"
                  label="Image"
                ></v-file-input>
                <span class="text-danger" v-if="$v.testimonial.imageFile.$error"
                  >Image is required</span
                >
                <span class="text-danger" v-if="sizeExceed"
                  >File Size 20MB Max Allowed</span
                >
              </v-col>

              <v-col cols="12" v-if="testimonial.image_path">
                <div class="text-center">
                  <img
                    :src="testimonial.image_path['thumb']"
                    alt
                    style="height:75px"
                    class="img-thumbnail"
                  />
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  v-model="testimonial.name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Type"
                  v-model="testimonial.type"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  label="Job Title"
                  v-model="testimonial.job_title"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <b-form-rating
                  v-model="testimonial.rating"
                  show-value
                  precision="2"
                ></b-form-rating>
              </v-col>

              <v-col cols="12" md="12">
                Description
                <ckeditor
                  :config="editorConfig"
                  v-model="testimonial.description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="3">
                <v-switch
                  v-model="testimonial.new_tab"
                  :label="testimonial.new_tab ? 'Yes' : 'No'"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="testimonial.is_active"
                  :label="testimonial.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>
            </v-row>
            <div class="row">
              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn
                    class="btn btn-standard text-gray"
                    depressed
                    @click="redirectTo"
                    >Cancel
                  </v-btn>
                  <v-btn
                    class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    dark
                    :disabled="sizeExceed"
                    @click="createOrUpdate"
                    :loading="isBusy"
                    >{{ id ? "Update" : "Save" }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import TestimonialService from "@/service/cms/testimonial/TestimonialService";

const testimonial = new TestimonialService();

export default {
  name: "testimonial-new",
  validations: {
    testimonial: {
      title: { required },
      position: { required },
      imageFile: {
        required: requiredIf(function() {
          return !this.id;
        })
      }
    }
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      value: 3.5,
      testimonial: {
        title: null,
        link: null,
        imageFile: null,
        position: null,
        new_tab: 0,
        description: null,
        type: null,
        is_active: true
      },
      rules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!"
      ],
      editorConfig: {
        toolbar: [
          [
            "Font",
            "FontSize",
            "FontColor",
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
            "TextColor",
            "BGColor"
          ]
        ],
        extraPlugins: ["font", "colorbutton"],
        colorButton_enableMore: true
      }
    };
  },
  mounted() {
    this.id ? this.getTestimonial(this.id) : "";
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  methods: {
    getTestimonial(id) {
      testimonial.show(id).then(response => {
        this.testimonial = response.data.testimonial;
        this.testimonial.is_active
          ? (this.testimonial.is_active = true)
          : false;
        this.testimonial.new_tab ? (this.testimonial.new_tab = true) : false;
        this.edit = true;
      });
    },
    changeImage() {
      this.testimonial.image_path = {
        thumb: URL.createObjectURL(this.testimonial.imageFile)
      };
    },
    redirectTo() {
      this.$router.push({ name: "testimonial" });
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      this.sizeExceed = mbSize > 20;
    },
    saveTestimonial(fd) {
      this.isBusy = true;
      testimonial
        .store(fd)
        .then(response => {
          this.isBusy = false;
          if (response.data.status === "OK") {
            this.$snotify.success("Created successfully");
            this.$tabs.close();
            this.$tabs.open({ name: "testimonial" });
          }
        })
        .catch(() => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    createOrUpdate() {
      this.$v.testimonial.$touch();
      if (this.$v.testimonial.$error) {
        setTimeout(() => {
          this.$v.testimonial.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateTestimonial(fd);
        } else {
          this.saveTestimonial(fd);
        }
      }
    },
    updateTestimonial(fd) {
      this.isBusy = true;
      testimonial
        .update(this.testimonial.id, fd)
        .then(response => {
          this.isBusy = false;
          if (response.data.status === "OK") {
            this.$snotify.success("Updated successfully");
            this.$tabs.close();
            this.$tabs.open({ name: "testimonial" });
          }
        })
        .catch(() => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.testimonial) {
        if (
          key == "imageFile" &&
          this.testimonial["imageFile"] &&
          this.testimonial["imageFile"] != null
        ) {
          if (typeof this.testimonial["imageFile"] == "object") {
            fd.append("imageFile", this.testimonial[key]);
          }
        } else if (key == "is_active") {
          fd.append("is_active", this.testimonial.is_active ? 1 : 0);
        } else {
          if (key != "id" && this.testimonial[key]) {
            fd.append(key, this.testimonial[key]);
          }
        }
      }
      return fd;
    }
  }
};
</script>
